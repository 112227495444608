import React from 'react';
import Layout from '../components/layout';
import NotFound from '../view/notfound';

import '../style/style.scss';

const NotFoundPage = props => {
  return (
    <Layout {...props}>
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
